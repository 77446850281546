import { Settings } from "../config";
import { EventType } from "./constants/ForwarderLander";
import { postEvent } from "./EventHelper";

export function createForwarderEvent(domainName, landerSelection, requestedAt) {
  const createdAt = new Date();

  return {
    eventType: EventType.FORWARDER,
    domain: domainName,
    url: window.location.href,
    createdAt: createdAt.toISOString(),
    forwardTo: landerSelection,
    // API call duration in MS
    apiCallDuration: createdAt - requestedAt,
  };
}

export function reportForwarderEvent(
  context,
  landerSelection,
  errorCode = null
) {
  const event = createForwarderEvent(
    context.domainName,
    landerSelection,
    context.requestedAt
  );

  if (errorCode !== null) {
    event.eventType = EventType.ERROR;
    event.errorCode = errorCode;
    event.forwarderApiStatus = context.xhr.status;
  }

  postEvent(Settings.EVENT_PUBLISH_API, event);
}
