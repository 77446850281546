export const ForwarderLanderType = {
  DAN: "DAN",
  TDFS_GD: "TDFS_GD",
  TDFS_AFTERNIC: "TDFS_AFTERNIC",
  PARKING: "PARKING",
};

export const QueryParam = {
  DOMAIN: "domain", // string: can be use to specify domain name
};

export const EventType = {
  FORWARDER: "FORWARDER",
  ERROR: "ERROR",
};

/*
 * Forwarder Application error codes
 * Separate from ErrorCode to make a forwarder prod html smaller
 */
export const ForwarderErrorCode = {
  ERROR_INVALID_STATUS_CODE: "ERROR_INVALID_STATUS_CODE",
  ERROR_API_ERROR: "ERROR_API_ERROR",
  ERROR_TIMEOUT: "ERROR_TIMEOUT",
  ERROR_CLIENT_ERROR: "ERROR_CLIENT_ERROR",
};
