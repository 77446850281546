/**
 * All methods are static.
 */
export default class Logger {
  static Level = {
    NONE: "NONE",
    ERROR: "ERROR",
    WARN: "WARN",
    INFO: "INFO",
    DEBUG: "DEBUG",
    TRACE: "TRACE",
  };

  static Severity = {
    NONE: 0,
    ERROR: 1,
    WARN: 2,
    INFO: 3,
    DEBUG: 4,
    TRACE: 5,
  };

  static logMessage(logLevel, message) {
    if (Logger.Severity[Logger.level] >= Logger.Severity[logLevel]) {
      let logger = "warn";
      if (logLevel === Logger.Level.ERROR) {
        logger = "error";
      }
      console[logger](`${logLevel}: ${toString(message)}`);
    }
  }

  static error(message) {
    return Logger.logMessage(Logger.Level.ERROR, message);
  }

  static warn(message) {
    return Logger.logMessage(Logger.Level.WARN, message);
  }

  static info(message) {
    return Logger.logMessage(Logger.Level.INFO, message);
  }

  static debug(message) {
    return Logger.logMessage(Logger.Level.DEBUG, message);
  }

  static trace(message) {
    return Logger.logMessage(Logger.Level.TRACE, message);
  }
}

// Default level
Logger.level = Logger.Level.NONE;

/**
 * Creates a string representation of object, array or string
 * @param val
 * @returns string
 */
export function toString(val) {
  if (typeof val === "object") {
    try {
      return JSON.stringify(val);
    } catch (e) {
      Logger.error(e);
      return val;
    }
  }
  if (Array.isArray(val)) {
    return val.toString();
  }
  return val;
}
