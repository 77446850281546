/*
 * NOTE: Functions here are shared with the forwarder. Check forwarder build size
 * when making change to this file.
 */

/**
 * Convert to boolean
 * @param val
 * @returns Boolean
 */
export function parseBoolean(val) {
  if (val === true || val === "true" || val === 1 || val === "1") {
    return true;
  } else if (val === false || val === "false" || val === 0 || val === "0") {
    return false;
  }
  return null;
}
