import { Settings } from "./config";
import {
  QueryParam,
  ForwarderErrorCode,
  ForwarderLanderType,
} from "./commons/constants/ForwarderLander";
import { reportForwarderEvent } from "./commons/ForwarderEvent";

const { ForwarderLanderToUrl, FORWARDER_LANDER_API: ForwarderLanderApi } =
  Settings;

export function getTargetLanderUrl(landerSelection, domain) {
  const query = window.location.search
    ? window.location.search.replace(/^\?/, "")
    : "";

  return ForwarderLanderToUrl[landerSelection]
    .replace(/{DOMAIN}/g, domain)
    .replace(/{QUERY}/, query);
}

export function onLoadHandler(context) {
  try {
    if (context.xhr.status !== 200) {
      if (context.xhr.status === 404) {
        reportForwarderEvent(
          context,
          ForwarderLanderType.TDFS_AFTERNIC,
          ForwarderErrorCode.ERROR_INVALID_STATUS_CODE
        );
        window.location.replace(
          getTargetLanderUrl(
            ForwarderLanderType.TDFS_AFTERNIC,
            context.domainName
          )
        );
        return;
      }
      errorHandler(context, ForwarderErrorCode.ERROR_INVALID_STATUS_CODE);
    } else {
      const landerSelection = context.xhr.response.landingPage;

      if (landerSelection in ForwarderLanderToUrl) {
        reportForwarderEvent(context, landerSelection);
        window.location.replace(
          getTargetLanderUrl(landerSelection, context.domainName)
        );
      } else if (landerSelection === ForwarderLanderType.PARKING) {
        reportForwarderEvent(context, landerSelection);
        loadLander();
      } else {
        throw new Error("not expected lander:" + landerSelection);
      }
    }
  } catch (err) {
    errorHandler(context, ForwarderErrorCode.ERROR_CLIENT_ERROR);
  }
}

export function errorHandler(context, reason) {
  reportForwarderEvent(context, ForwarderLanderType.TDFS_AFTERNIC, reason);
  window.location.replace(
    getTargetLanderUrl(ForwarderLanderType.TDFS_AFTERNIC, context.domainName)
  );
}

export function getDomainName() {
  const params = new URLSearchParams(window.location.search);
  const domain = params.get(QueryParam.DOMAIN);

  if (domain != null && domain !== "") {
    return domain;
  }

  return window.location.hostname;
}

function loadLander() {
  window.LANDER_SYSTEM = "CP";
  const cafScript = document.createElement("script");
  cafScript.setAttribute("type", "text/javascript");
  cafScript.setAttribute(
    "src",
    "https://www.google.com/adsense/domains/caf.js?abp=1"
  );
  cafScript.onload = cafScript.onerror = function () {
    import("./index").then((module) => {
      module.default();
    });
  };
  document.head.appendChild(cafScript);
}

// Main driver
function runForwarder() {
  const xhr = new XMLHttpRequest();
  const context = {
    domainName: getDomainName(),
    xhr,
    requestedAt: new Date(),
  };

  const url = `${ForwarderLanderApi}/v1/aftermarket/landers/${context.domainName}`;
  xhr.timeout = 2000;
  xhr.responseType = "json";
  xhr.open("GET", url);

  xhr.onload = () => {
    onLoadHandler(context);
  };

  xhr.onerror = () => {
    errorHandler(context, ForwarderErrorCode.ERROR_API_ERROR);
  };
  xhr.ontimeout = () => {
    errorHandler(context, ForwarderErrorCode.ERROR_TIMEOUT);
  };

  xhr.send();
}

runForwarder();
export default runForwarder;
